#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

#root > div {
  flex-grow: 1;
}

#root > div:first-child,
#root > div:last-child {
  flex-grow: 0;
}

.u42-divider {
  border-left: 1px solid #777777;
  width: 0;
  flex: 0 0 auto;
}

.u42-full-width-center {
  width: 100vw;
  text-align: center;
  vertical-align: middle;
}

.u42-constrained-width {
  max-width: 450px;
  margin: auto;
}

.recaptcha > div > div {
  width: unset !important;
}

.ui.table > thead > tr > th.actions-header {
  text-align: right;
}

.u42-nav-container {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.u42-nav-bar {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border-right: 1px solid #777777;
  margin-right: 5px;
}

.u42-nav-bar > a {
  display: inline-flex;
  align-items: baseline;
  margin: 3px;
  padding: 5px 5px 0 0;
}

.u42-nav-bar > a:first-child {
  padding: 0 5px 0 0;
}

.u42-nav-bar > a:hover {
  text-decoration: none;
}

.u42-nav-bar > a.active:not(hover) {
  color: #f27117a0;
}

.u42-home {
  flex-direction: column;
  display: flex;
  font-size: 1.8rem;
}

.u42-home > div {
  align-self: center;
}

.u42-home > div > img.ui.image {
  display: inline-block;
  margin: 5px;
}

.ui.menu.u42-topmenu {
  border: none;
  font-size: 1.8rem;
}

.u42-table-container {
  flex: 1 1 auto;
}

.u42-table-container > .ui.segment {
  margin-top: 1em;
}

.u42-table-container > .ui.segment:first-child {
  margin-top: 0;
}

.u42-table-container > .ui.segment > .ui.header,
.u42-table-container > .ui.segment > .ui.table {
  margin: 0;
}

.ui.table.u42-narrow {
  width: unset;
}

.u42-footer {
  z-index: -1;
}

.qrcode > canvas {
  border: 5px solid white;
}
